.model {
  position: relative;
  z-index: 500;
  background: white;
  border: solid 3px black;
  border-radius: 50%;
  padding: 8px;
  font-size: 5px;
  opacity: 0.7;
}

.info1 {
  z-index: 2500;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
  font-size: 22px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  font-weight: bold;
  width: auto;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
}

.info2 {
  flex: 1;
  color: black;
  padding: 2px;
  margin: 2px;
  height: auto;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}
