* {
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  font-family: 'Roboto';
}

#container {
  width: 200px;
  height: 200px;
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}

.dual-ring {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: space-around;
  flex-direction: column-reverse;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.dual-ring:after {
  content: ' ';
  display: inline-block;
  width: clamp(15px, 10vh, 64px);
  height: clamp(15px, 10vh, 64px);
  margin: 8px;
  border-radius: 50%;
  border: 4px solid rgb(0, 0, 0);
  border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingText {
  font-family: 'Roboto';
  font-weight: 900;
  font-size: clamp(12px, 5vh, 30px);
  margin: 2em 10%;
}

/* Tap Gesture Animation */
@keyframes tap-ges-anim {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(30px);
  }
  75% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* Tap Gesture styles */
.inter-ins {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  font-size: clamp(0.65rem, 3vh, 1rem);
}
.zoom-ins {
  position: absolute;
  top: 0;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .mob-ins {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .deskp-ins {
    display: none;
  }
}
.gesture {
  width: fit-content;
  margin: auto;
}
.tap-gesture {
  width: clamp(1rem, 7vh, 2rem);
  animation: tap-ges-anim 5s linear infinite;
}
