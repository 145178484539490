@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.canvas {
  cursor: grab;
}
.canvas:active {
  cursor: grabbing;
}

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  font-family: 'Roboto';
}

#container {
  width: 200px;
  height: 200px;
}

@-webkit-keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-name: animation;
          animation-name: animation;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier;
          animation-timing-function: cubic-bezier;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.dual-ring {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: space-around;
  flex-direction: column-reverse;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.dual-ring:after {
  content: ' ';
  display: inline-block;
  width: clamp(15px, 10vh, 64px);
  height: clamp(15px, 10vh, 64px);
  margin: 8px;
  border-radius: 50%;
  border: 4px solid rgb(0, 0, 0);
  border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
  -webkit-animation: dual-ring 1.2s linear infinite;
          animation: dual-ring 1.2s linear infinite;
}

@-webkit-keyframes dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loadingText {
  font-family: 'Roboto';
  font-weight: 900;
  font-size: clamp(12px, 5vh, 30px);
  margin: 2em 10%;
}

/* Tap Gesture Animation */
@-webkit-keyframes tap-ges-anim {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  25% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  75% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes tap-ges-anim {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  25% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  75% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

/* Tap Gesture styles */
.inter-ins {
  position: absolute;
  top: 24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  font-size: clamp(0.65rem, 3vh, 1rem);
}
.zoom-ins {
  position: absolute;
  top: 0;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .mob-ins {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .deskp-ins {
    display: none;
  }
}
.gesture {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.tap-gesture {
  width: clamp(1rem, 7vh, 2rem);
  -webkit-animation: tap-ges-anim 5s linear infinite;
          animation: tap-ges-anim 5s linear infinite;
}

.model {
  position: relative;
  z-index: 500;
  background: white;
  border: solid 3px black;
  border-radius: 50%;
  padding: 8px;
  font-size: 5px;
  opacity: 0.7;
}

.info1 {
  z-index: 2500;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  color: black;
  font-size: 22px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  font-weight: bold;
  width: auto;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
}

.info2 {
  flex: 1 1;
  color: black;
  padding: 2px;
  margin: 2px;
  height: auto;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}

